











































import { IAssetSearchResult } from "@/core/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import CompanyLogo from "../base/CompanyLogo.vue";

@Component({
  components: {
    CompanyLogo,
  },
})
export default class SearchItem extends Vue {
  @Prop(Number) readonly index!: number;
  @Prop(Object) readonly source!: IAssetSearchResult;

  get search(): string {
    return this.$store.state.search.query;
  }

  // This getter returns the custom name html with the search query highlighted
  get customName(): string {
    const index = this.source.customName
      .toLowerCase()
      .indexOf(this.search.toLowerCase());
    if (index === -1) {
      return this.source.customName;
    }
    return (
      this.source.customName.substring(0, index) +
      "<span class='search-highlight'>" +
      this.source.customName.substring(index, index + this.search.length) +
      "</span>" +
      this.source.customName.substring(index + this.search.length)
    );
  }
  get symbol(): string {
    const index = this.source.symbol
      .toLowerCase()
      .indexOf(this.search.toLowerCase());
    if (index === -1) {
      return this.source.symbol;
    }
    return (
      this.source.symbol.substring(0, index) +
      "<span class='search-highlight'>" +
      this.source.symbol.substring(index, index + this.search.length) +
      "</span>" +
      this.source.symbol.substring(index + this.search.length)
    );
  }
}
