

































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import SearchIcon from "vue-material-design-icons/Magnify.vue";
import ClearIcon from "vue-material-design-icons/Close.vue";
import { IAssetSearchResult } from "@/core/types";
import VirtualList from "vue-virtual-scroll-list";
import SearchItem from "./SearchItem.vue";
import { toggleScroll } from "@/core";

@Component({
  components: {
    SearchIcon,
    ClearIcon,
    VirtualList,
  },
})
export default class SearchContainer extends Vue {
  focused = false;
  search = "";
  keysPressed: Record<string, boolean> = {};
  itemComp = SearchItem;

  get searchInput(): HTMLInputElement {
    return this.$refs.searchInput as HTMLInputElement;
  }
  get loadingSearch(): boolean {
    return this.$store.state.search.loadingSearch;
  }
  get popularAssets(): IAssetSearchResult[] {
    return this.$store.state.search.popularAssets;
  }
  get showPopular(): boolean {
    return this.search.length < 1;
  }
  get searchResults(): IAssetSearchResult[] {
    return this.$store.state.search.searchResults;
  }
  get showSearchResults(): boolean {
    return this.search.length > 0;
  }
  get totalResults(): number {
    return this.$store.state.search.totalResults;
  }
  get searchResultsEl(): any {
    return this.$refs.searchResults;
  }
  get isMac(): boolean {
    // todo: move this to utils
    return navigator.userAgent.includes("Mac");
  }

  onKeydown(e: KeyboardEvent): void {
    this.keysPressed[e.key] = true;

    if (e.key === "Escape") {
      this.focused = false;
      this.searchInput.blur();
      this.keysPressed = {};
    }
    if (this.keysPressed["Meta"] && this.keysPressed["k"]) {
      this.focus();
    }
    if (this.keysPressed["Ctrl"] && this.keysPressed["k"]) {
      this.focus();
    }

    // If user focuses outside of search container turn off overlay
    if (this.keysPressed["Tab"]) {
      const activeEl = document.activeElement as HTMLElement;
      const searchContainer = document.getElementById(
        "search-container"
      ) as HTMLElement;

      if (!searchContainer.contains(activeEl)) {
        this.focused = false;
      }
    }
  }
  onKeyup(e: KeyboardEvent): void {
    delete this.keysPressed[e.key];
  }
  onOverlayClick(): void {
    this.focused = false;
    this.searchInput.blur();
    this.keysPressed = {};
  }
  clear(): void {
    this.search = "";
  }
  focus(): void {
    this.focused = true;
    this.searchInput.focus();
  }
  async onScrollToBottom(): Promise<void> {
    if (this.searchResults.length <= this.totalResults) {
      await this.$store.dispatch("search/loadMore", this.search);
    }
  }

  created(): void {
    if (!this.popularAssets.length) {
      this.$store.dispatch("search/fetchPopularAssets");
    }
  }

  onSearchKeyup(): void {
    if (this.search.length > 0) {
      this.$store.dispatch("search/searchAssets", this.search);
    }
  }

  mounted(): void {
    document.addEventListener("keydown", this.onKeydown);
    document.addEventListener("keyup", this.onKeyup);
  }

  beforeDestroy(): void {
    document.removeEventListener("keydown", this.onKeydown);
    document.removeEventListener("keyup", this.onKeyup);
  }

  // When search is focused, we block scrolling on the body so its easier to scroll on the search results.
  @Watch("focused")
  onFocusedOn(val: boolean): void {
    if (val) {
      toggleScroll(false);
    } else {
      toggleScroll(true);
    }
  }

  get routePath(): string {
    return this.$route.path;
  }

  @Watch("routePath")
  onRoutePathChange(): void {
    this.focused = false;
    this.searchInput.blur();
    this.keysPressed = {};
  }
}
