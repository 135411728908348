
























import { Component, Prop, Vue } from "vue-property-decorator";
import { getAssetURL } from "@/core/base/FirebaseService";
@Component({})
export default class extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  readonly symbol!: string;
  @Prop(Number) readonly size?: number;

  loading = true;
  logoURL: string | undefined = "";

  async getCompanyLogo(): Promise<void> {
    this.loading = true;
    this.logoURL = await getAssetURL(this.symbol);
    this.loading = false;
  }
  get logoAlt(): string {
    return `${this.symbol} logo`;
  }

  visibilityChanged(isVisible: boolean): void {
    if (isVisible && !this.logoURL) {
      this.getCompanyLogo();
    }
  }
}
