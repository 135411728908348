var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[_c('header',{staticClass:"nn-container mx-auto py-5 px-4 flex justify-between items-center"},[_c('NinetyNineLogo',{staticClass:"mr-6",attrs:{"link":""}}),_c('div',{staticClass:"hidden w-2/5 md:flex flex-grow max-w-md"},[_c('SearchContainer')],1),_c('div',{staticClass:"space-x-6 hidden md:flex"},[_c('router-link',{directives:[{name:"track",rawName:"v-track:click",value:({
          label: 'header_nav_click_portfolio',
        }),expression:"{\n          label: 'header_nav_click_portfolio',\n        }",arg:"click"}],staticClass:"px-4 py-2 inline-block",attrs:{"to":"/cartera"}},[_vm._v(_vm._s(_vm.$t("NavBar_Portfolio_Title")))]),_c('div',[_c('VDropdown',{staticClass:"py-2 px-4",attrs:{"text":_vm.$t('NavBar_Profile_Title'),"transition":"slide-up"}},[_c('div',{staticClass:"p-8 bg-white shadow-md rounded-sm right-32"},[_c('div',{staticClass:"hover:bg-blue-50 w-40"},[_c('router-link',{directives:[{name:"track",rawName:"v-track:click",value:({
                  label: 'header_click_logout',
                }),expression:"{\n                  label: 'header_click_logout',\n                }",arg:"click"}],staticClass:"inline-block px-4 py-2",attrs:{"to":{ name: 'logout' }}},[_vm._v(_vm._s(_vm.$t("Profile_LogOut_Button")))])],1)])])],1)],1),_c('div',{staticClass:"md:hidden flex items-center"},[_c('button',{on:{"click":_vm.toggleMobileMenu}},[_vm._m(0)])])],1),_c('transition',{attrs:{"name":"slide-fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMobileMenu),expression:"showMobileMenu"}],staticClass:"flex flex-col fixed left-0 top-0 w-full h-screen bg-white z-10"},[_c('header',{staticClass:"flex justify-between items-center py-10 px-4"},[_c('span',{staticClass:"ml-2"},[_c('img',{attrs:{"src":require("@/assets/img/logo.svg"),"alt":""}})]),_c('button',{on:{"click":_vm.toggleMobileMenu}},[_c('CloseIcon')],1)]),_c('nav',{staticClass:"text-xl flex flex-col gap-4 p-4",on:{"click":_vm.toggleMobileMenu}},[_c('router-link',{directives:[{name:"track",rawName:"v-track:click",value:({
            label: 'header_nav_click_portfolio',
          }),expression:"{\n            label: 'header_nav_click_portfolio',\n          }",arg:"click"}],staticClass:"px-4 py-2",attrs:{"to":{ name: 'portfolio' }}},[_vm._v(_vm._s(_vm.$t("NavBar_Portfolio_Title")))]),_c('router-link',{directives:[{name:"track",rawName:"v-track:click",value:({
            label: 'header_nav_click_logout',
          }),expression:"{\n            label: 'header_nav_click_logout',\n          }",arg:"click"}],staticClass:"px-4 py-2",attrs:{"to":{ name: 'logout' }}},[_vm._v(_vm._s(_vm.$t("Profile_LogOut_Button")))])],1)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"burger-icon"},[_c('span'),_c('span'),_c('span')])}]

export { render, staticRenderFns }