







import { Component, Vue } from "vue-property-decorator";
import AppHeader from "./AppHeader.vue";
@Component({
  components: {
    AppHeader,
  },
})
export default class AppLayout extends Vue {}
