



















































































import { Component, Vue } from "vue-property-decorator";
import NinetyNineLogo from "@/components/base/NinetyNineLogo.vue";
import { VDropdown } from "vuetensils/src/components";
import CloseIcon from "vue-material-design-icons/Close.vue";
import SearchContainer from "@/components/search/SearchContainer.vue";
@Component({
  components: {
    NinetyNineLogo,
    VDropdown,
    SearchContainer,
    CloseIcon,
  },
})
export default class AppHeader extends Vue {
  showMobileMenu = false;

  toggleMobileMenu(): void {
    this.showMobileMenu = !this.showMobileMenu;
  }
}
